.App {
  display: flex;

  flex: 1;
  flex-direction: row;

  width: 100vw;
  height: 100vh;

}

.ideContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.timer {
  position: absolute;

  bottom: 50px;
  right: 50px;

  background-color: #D800A6;

  color: #d6deeb;

  padding: 5px;
};