.container {
  display: flex;
  flex-direction: column;

  width: 65vw;
  height: 50vh;

  background-color: #011627;
}

.container h3 {
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 5px;

  color: #d6deeb;

  border-bottom-width: 0.1px;
  border-bottom-style: solid;

  border-color: #80a4c2;
}

.tag {
  display: flex;
  flex-direction: row;

  align-items: center;

  padding: 5px;
}

.tag img {
  width: 20px;
  height: 20px;
}

.tag h3 {
  font-size: 15px;
  font-style: none;

  font-weight: bold;
  line-height: 0;
}
