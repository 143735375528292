.container {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;

  background-color: #011627;
}

.preview {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #865dff72;

  width: 90vw;
  height: 80vh;
}
