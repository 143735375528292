.container {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100px;
  height: 100px;

  background-color: red;

  margin: 5px;

  border-style: none;
}

.containerSelected {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100px;
  height: 100px;

  background-color: green;

  margin: 5px;

  border-style: none;
}
