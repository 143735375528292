@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 15px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;

  background-color: #011627;
}

.container h2,
.container h3 {
  font-size: 1rem;
  color: #d6deeb;
}

.usersContainer {
  text-align: center;
}

.usersContainer__list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  max-width: 95%;
  padding: 2rem;
}

.usersContainer__list li {
  color: #d6deeb;

  margin: 1rem;

  width: 100px;
  height: 100px;
  border: 2px solid #865dff;
  background-color: #d6deeb37;
  border-radius: 100%;

  animation-name: floating;
  animation-duration: 3s;

  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
}

.usersContainer__list li span {
  width: 100%;
  top: 100%;
  left: 0;
  text-align: center;
  position: absolute;
  font-size: 0.85rem;
  padding: 0.5rem;
}

.usersContainer__list li i {
  width: 50%;
  display: block;
}

.usersContainer__list li svg {
  width: 100%;
}

.usersContainer__list li path {
  fill: #865dff;
}
