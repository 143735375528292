.container {
  width: 100%;
  height: 100%;

  background-color: #011627;

  margin: 0px;
  padding: 0px;
}

.previewContainer {
  width: 300px;
  height: 200px;
  background-color: orange;
  margin: 0px;
}

.buttonRow {
  display: flex;
  flex-direction: row;

  width: 100%;

  justify-content: space-around;

  margin-top: 40px;
}

.buttonRow button {
  width: 50px;
  height: 50px;
}
